import React from 'react';
import './app.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import AboutMe from './pages/AboutMe/AboutMe';
import ProjectPage from './pages/Projects/ProjectPage';

import NavigationHeader from './components/Header';
import Hobbies from './pages/Hobbies/Hobbies';
import TechStack from './pages/TechStack/TechStack';
import TechnicalJobs from './pages/Experience/Technical/TechnicalJobs';
import TransferableJobs from './pages/Experience/Transferrable/TransferableJobs';
import Classes from './pages/Experience/Classes/Classes';
import ContactMe from './pages/ContactMe/ContactMe';
function App() {
  return (
    <Router>
        {/* <NavigationHeader /> */}
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/Projects" element={<ProjectPage />} />
        </Routes>
    </Router>
  );
}

export default App;

// <Route path="/AboutMe" element={<AboutMe />} />
// <Route path="/Experience" element={<ExperiencePage />} />
// <Route path="/Hobbies" element={<Hobbies />} />
// <Route path="/Projects" element={<ProjectPage />} />
// <Route path="/Technical" element={<TechnicalJobs />} />
// <Route path="/Transferable" element={<TransferableJobs />} />
// <Route path="/Classes" element={<Classes />} />
// <Route path="/ContactMe" element={<ContactMe />} />