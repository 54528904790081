import React from 'react';
import javaImage from '../../image/computerknowledge/logos/java.jpeg';
import pythonImage from '../../image/computerknowledge/logos/python.jpeg';
import reactImage from '../../image/computerknowledge/logos/react.jpeg';
import jsImage from '../../image/computerknowledge/logos/javascript.jpeg';
import cImage from '../../image/computerknowledge/logos/c.jpeg';
import htmlImage from '../../image/computerknowledge/logos/html.jpeg';
import cssImage from '../../image/computerknowledge/logos/css.jpeg';
import linuxImage from '../../image/computerknowledge/logos/linux.jpeg';
import cloudImage from '../../image/computerknowledge/logos/google cloud.jpeg';
import springBootImage from '../../image/computerknowledge/logos/springboot.jpeg';
import mongodbImage from '../../image/computerknowledge/logos/mongodb.jpeg';
import eclipseImage from '../../image/computerknowledge/logos/eclipse.jpeg';
import intellijImage from '../../image/computerknowledge/logos/intellij.jpeg';
import vscodeImage from '../../image/computerknowledge/logos/vscode.jpeg';
import gitImage from '../../image/computerknowledge/logos/git.jpeg';
import oracleVmImage from '../../image/computerknowledge/logos/oraclevm.jpeg';
import drRacketImage from '../../image/computerknowledge/logos/drracket.jpeg';
import './TechStack.css';

const techStackData = [
  { name: 'Java', image: javaImage },
  { name: 'Python', image: pythonImage },
  { name: 'React', image: reactImage },
  { name: 'JavaScript', image: jsImage },
  { name: 'C', image: cImage },
  { name: 'HTML', image: htmlImage },
  { name: 'CSS', image: cssImage },
  { name: 'DrRacket', image: drRacketImage },
  { name: 'Linux', image: linuxImage },
  { name: 'Google Cloud', image: cloudImage },
  { name: 'Spring Boot', image: springBootImage },
  { name: 'MongoDB', image: mongodbImage },
  { name: 'Eclipse', image: eclipseImage },
  { name: 'IntelliJ IDEA', image: intellijImage },
  { name: 'VS Code', image: vscodeImage },
  { name: 'Git', image: gitImage },
  { name: 'Oracle VM', image: oracleVmImage },
];

const TechStack: React.FC = () => {
  return (
    <div className="techStackContainer">
      <h1 className="techStackHeader">Tech Stack</h1>
      <div className="techStackGrid">
        {techStackData.map((tech) => (
          <div key={tech.name} className="techStackItem">
            <img src={tech.image} alt={tech.name} className="techStackImage" />
            <p>{tech.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TechStack;
