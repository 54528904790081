import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async'; // Import from react-helmet-async
import headshot from '../../image/headshot.png';
import linkedin from '../../image/linkedin.png';
import github from '../../image/github.png';
import BusinessCard from '../../components/Card'; // Import the BusinessCard component
import TechStack from '../TechStack/TechStack'; // Import the TechStack component
import './HomePage.css'; // Import the CSS file
import TechnicalJobs from '../Experience/Technical/TechnicalJobs';
import TransferableJobs from '../Experience/Transferrable/TransferableJobs';
import Classes from '../Experience/Classes/Classes';
import Header from '../../components/Header'; // Import the Header component
import ContactMe from '../ContactMe/ContactMe';
import ProjectPage from '../Projects/ProjectPage';

const HomePage: React.FC = () => {
  const [displayPDF, setDisplayPDF] = useState<boolean>(false);
  const [showContactInfo, setShowContactInfo] = useState<boolean>(false);

  const toggleContactInfo = () => {
    setShowContactInfo((prevState) => !prevState);
  };

  return (
    <HelmetProvider>
      <div className="home-container">
        {/* SEO Header */}
        <Helmet>
          <title>Elizabeth McDowell's Personal Website</title>
          <meta name="description" content="Elizabeth McDowell is an aspiring software engineer currently studying at Northeastern University. Explore her projects, tech stack, and experience on her personal website." />
          <meta name="keywords" content="Elizabeth McDowell, software engineer, Northeastern University, tech stack, experience, personal website, swe, react, css, programming, coding, coder, elizabeth, mcdowell, liz, liz mcdowell, technology, ai, frontend, backend, design, teacher, intern, HHHS, NEU, Hendrick Hudson, Job" />
          <meta property="og:title" content="Elizabeth McDowell Personal Website" />
          <meta property="og:description" content="Elizabeth McDowell is a software engineer currently studying at Northeastern University. Explore her projects, tech stack, and experience on her personal website." />
          <meta property="og:image" content="/path/to/your/image.jpg" />
          <meta property="og:url" content="https://elizabethmcdowell.dev/" />
          <meta property="og:type" content="website" />
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org/",
                "@type": "Person",
                "name": "Elizabeth McDowell",
                "jobTitle": "Software Engineer",
                "worksFor": {
                  "@type": "Organization",
                  "name": "Northeastern University"
                },
                "url": "https://elizabethmcdowell.dev/",
                "sameAs": [
                  "https://www.linkedin.com/in/elizabeth-mcdowell-northeastern/",
                  "https://github.com/elizabeth-mcdowell"
                ]
              }
            `}
          </script>
        </Helmet>

        {/* Header Section */}
        <Header />

        {/* Adjust content padding to account for fixed header */}
        <div className="header-section" style={{ paddingTop: '4rem' }}>
          <div className="headshot-container">
            <img src={headshot} alt="Elizabeth McDowell Headshot" className="headshot-image" />
          </div>
          <div className="intro-text">
            <h1>Welcome to My Homepage</h1>
            <p>
              Hi! I'm Elizabeth McDowell.
            </p>
            <p>
              I'm a current 4th Year at Northeastern University with a 
              Bachelor of Science in Computer Science with a Human Centered Computing Concentration.
              My passion is the intersection between technology and production and thinking of creative 
              new ways to solve old issues.
            </p>
          </div>
        </div>

        {/* Social Links */}
        <div className="social-links">
          <a
            href="https://www.linkedin.com/in/elizabeth-mcdowell-northeastern/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <img src={linkedin} alt="LinkedIn" className="social-icon" />
          </a>

          <a
            href="https://github.com/elizabeth-mcdowell"
            target="_blank"
            rel="noopener noreferrer"
            className="social-link"
          >
            <img src={github} alt="GitHub" className="social-icon" />
          </a>
        </div>

        {/* PDF and Contact Info Section */}
        <div className="info-section">
          <button
            onClick={() => setDisplayPDF((prevDisplay) => !prevDisplay)}
            className="button resume-button"
          >
            {displayPDF ? 'Close Resume' : 'View Resume'}
          </button>

          {displayPDF && (
            <div className="resume-content">
              <a href="./resume.pdf" download className="download-button">
                Download Resume
              </a>
              <iframe title="Elizabeth McDowell Resume" src="./Elizabeth-McDowell-Resume.pdf" className="pdf-frame" />
            </div>
          )}

          <button onClick={toggleContactInfo} className="button contact-button">
            {showContactInfo ? 'Close Info' : 'Contact Info'}
          </button>

          {/* Conditionally render BusinessCard component */}
          {showContactInfo && <BusinessCard />}
        </div>

        <hr className="section-divider" />
        {/* Project Stack section */}
      
        <section id="projects">
          <ProjectPage />
        </section>
        <hr className="section-divider" />

        {/* Tech Stack section */}
        <section id="techstack">
          <TechStack />
        </section>
        <hr className="section-divider" />

        {/* Experience Page Section */}
        <section id="technicaljobs">
          <TechnicalJobs />
        </section>
        <hr className="section-divider" />
        <section id="transferablejobs">
          <TransferableJobs />
        </section>
        <hr className="section-divider" />
        <section id="classes">
          <Classes />
        </section>
        <hr className="section-divider" />
        <section id="contactme">
          <ContactMe />
        </section>
        <hr className="section-divider" />

        {/* Hobbies Section */}
        {/* <section id="hobbies">
          <Hobbies />
        </section> */}
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
