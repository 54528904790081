import React from 'react';
import '../JobsTimeline.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';

const transferableJobsData = [

  {
    title: 'Northeastern Husky Ambassador',
    company: 'Northeastern University',
    date: 'Nov 2021 - Present',
    duration: '3 Years (Part Time)',
    location: 'Boston, Massachusetts, United States',
    description: 'Served as an undergraduate student leader dedicated to providing campus visitors with a view into the Northeastern student experience: led campus tours for prospective Northeastern students and their families.',
  },
  {
    title: 'Sexual Violence Prevention Educator',
    company: 'Office for Prevention and Education at Northeastern',
    date: 'Sep 2023 - Present',
    duration: '1 Year (Part Time)',
    location: 'Boston, Massachusetts, United States',
    description: 'Gave presentations to freshman year classes about bystander intervention, consent, alcohol & drugs awareness, and sexual violence resources on campus to create a more inclusive environment.',
  },
  {
    title: 'Bumble Brand Ambassador',
    company: 'Bumble Inc.',
    date: 'Feb 2024 - Jun 2024',
    duration: '5 mos (Part Time)',
    location: 'Boston, Massachusetts, United States',
    description: 'Organized Bumble Brand Sponsored events acting as a liaison for local businesses, achieving over 200 new sign-ups. Worked with campus clubs to arrange in-club celebrations to spread the mission. Reached over 3000 people on social media for Bumble.',
  },
  {
    title: 'Teaching Assistant',
    company: 'A Rising Star Children\'s Center',
    date: 'Jun 2022 - Aug 2022',
    duration: '3 mos',
    location: 'Cortlandt Manor, New York, United States',
    description: 'Monitored and supervised children (ages 2-5) to keep them safe and healthy. Organized activities designed to help children learn, explore, and develop. Facilitated and assisted children with expanding and developing language and communication skills. Cultivated creativity with exposure to art, music, and dance.',
  },

];

const TransferableJobs: React.FC = () => {
  return (
    <div className="jobs-section">
      <h1>Additional Experience</h1>
      <ul className="timeline-container">
        {transferableJobsData.map((job, index) => (
          <li key={index} className="timeline-item">
            <FontAwesomeIcon icon={faBriefcase} className="timeline-icon" />
            <div className="timeline-content">
              <b>{job.title}</b> at {job.company}
              <br />
              <i>{job.date}</i>
             
              <p>{job.location}</p>
              <p>{job.description}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TransferableJobs;
