import React, { useState } from 'react';
import './ProjectPage.css';

const projects = [
  {
    id: 1,
    title: 'Survivor Northeastern Website',
    description: 'Built the website for Survivor Northeastern.  All data is separated from the code so that a non-programmer can easily add information. ',
    technologies: 'Python, React, HTML, CSS',
    image: '/images/survivor.png',
    link: 'https://survivor-northeastern.vercel.app/',
  },
  {
    id: 4,
    title: 'Spreadsheet',
    description: 'Used the Model-View-Controller method to create spreadsheets from scratch.',
    technologies: 'Typescript, CSS',
    image: '/images/spreadsheet.png',
    link: ' https://youtu.be/BV1YlfGyioY',
  },
  {
    id: 7,
    title: 'Cactus Study',
    description: 'Placed in Hackbeanpot. Python Backend to store information with a React.js frontend to make it easy for users to study for free since new apps have cost.',
    technologies: 'Python, React.js, CSS',
    image: '/images/cactus.png',
    link: 'https://youtu.be/51LGrRZlq_k',
  },

  {
    id: 9,
    title: 'Minesweeper',
    description: 'Made a fun mock of Minesweeper to get a better handle of pure HTML - plus I love Minesweeper! ',
    technologies: 'HTML, Javascript, CSS',
    image: '/images/minesweeper.png',
    link: 'https://youtube.com/shorts/370mLa63DmA?feature=share',
  },
  {
    id: 11,
    title: 'News Aggregator',
    description: 'Focusing on working with calling and manipulating API calls using axios',
    technologies: 'Javascript, CSS',
    image: '/images/news.png',
    link: 'https://youtu.be/fYrBMIHc1LE',
  },

];

const ProjectPage: React.FC = () => {
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

  const handleNext = () => {
    setCurrentProjectIndex((prevIndex) =>
      prevIndex === projects.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentProjectIndex((prevIndex) =>
      prevIndex === 0 ? projects.length - 1 : prevIndex - 1
    );
  };

  return (
    <section className="projects">
      <h1>My Projects</h1>
      <p> These are just some fun projects in my free time! Code provided on request.</p>
      <div className="project-grid">
        {projects.map((project) => (
          <div key={project.id} className="project-item">
            <img src={project.image} alt={`${project.title} Image`} className="project-image" />
            <div className="project-details">
              <h2>{project.title}</h2>
              <p><strong>Description:</strong> {project.description}</p>
              <p><strong>Technologies:</strong> {project.technologies}</p>
              {project.link && (
                <a href={project.link} className="project-link" target="_blank" rel="noopener noreferrer">
                  View Project
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
     
    </section>
  );
}

export default ProjectPage;
