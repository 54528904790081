import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Import EmailJS
import './ContactMe.css'; // Import CSS for styling the contact form

const ContactMe: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const templateParams = {
      to_name: 'Lizzy',
      from_name: email,
      message: message,
    };

    emailjs.send('service_vf74u6k', 'template_6w22zei', templateParams, 'QKbNZwnHFez085PIE')
      .then((response) => {
        alert('Message sent!');
        setEmail('');
        setMessage('');
      })
      .catch((error) => {
        console.error('Failed to send email:', error);
        alert('Failed to send message. Please try again later.');
      });
  };

  return (
    <div className="contact-me-container">
      <h1>Contact Me</h1>
      <form onSubmit={handleSubmit} className="contact-form">
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Message"
          required
        ></textarea>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ContactMe;
