import React from 'react';
import './Classes.css';

const Classes: React.FC = () => {
  return (
    <div className="classes-page">
      <h1>Classes</h1>
      <div className="current-classes">
        <h2>Fall 2024</h2>
        <ul>
          <li>Human Computer Interaction</li>
          <li>Principles of Information Science</li>
          <li>Theory of Computation</li>
          <li>Advanced Writing</li>
        </ul>
      </div>
      <div className="previous-courses">
        <h2>Previous Courses</h2>
        <div className="courses-columns">
          <div className="column">
            <h4>Technical</h4>
            <ul>
              <li>Algorithms & Data</li>
              <li>Foundations of Cybersecurity</li>
              <li>Object-Oriented Design</li>
              <li>Fundamentals of Computer Science I & II</li>
              <li>Discrete Structures</li>
              <li>Mathematics of Data Models</li>
              <li>Intro to Math Reasoning</li>
              <li>Directed Study for Computer Science</li>
              <li>Information System Design & Development</li>
              <li>Computer Systems</li>
              <li>Empirical Research Methods</li>
              <li>Fundamentals of Software Engineering</li>
            </ul>
          </div>
          <div className="column">
            <h4>Transferable</h4>
            <ul>
              <li>Cognition</li>
              <li>Dynamic Earth</li>
              <li>History of Technology</li>
              <li>American Sign Language</li>
              <li>Introduction to Contemporary Moral Issues</li>
              <li>Improvisation</li>
              <li>Introduction to Acting</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Classes;
