import React, { useState } from 'react';

const BusinessCard: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [emailContent, setEmailContent] = useState('');


  return (
    <div className="Contact Info">
      <h2>Contact Info</h2>
      <p><b>Email:</b> ekmcdowell2021@gmail.com</p>
      <p><b>Phone</b>: +1 914 268 8516</p>
      <p><b>Current Location</b>: New York</p>

    </div>
  );
};

export default BusinessCard;
