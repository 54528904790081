import React from 'react';
import '../JobsTimeline.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';

const transferableJobsData = [
  {
    title: 'Software Engineer',
    company: 'Publicis Sapient',
    date: 'Jun 2024 - Aug 2024',
    duration: 'Ongoing',
    location: 'New York City, NY',
    description: 'Completed two major projects as a full-stack engineer in an agile development environment using a Java backend, Spring Boot framework, MongoDB, React, and Artificial Intelligence. Collaborated with key clients with over 2,200 stores to satisfy project requirements and completed over 200 hours of training with technology.',
  },
  {
    title: 'Cyber Crime Assistant',
    company: 'Attorney General’s Office',
    date: 'Jan 2024 – May 2024',
    duration: '5 mos',
    location: 'Boston, MA',
    description: 'Led a team of over 10 people to plan for and execute the National Cyber Crime Conference by communicating with speakers, attendees, and vendors to plan over 200 sessions, secure over 20 vendors, and attract over 1000 attendees. Conducted digital forensic analytics for current and active legal cases and filled out Cyber Tips to support police investigations.',
  },
  {
    title: 'Software Development Engineer',
    company: 'Travelers',
    date: 'Jan 2023 - Jun 2023',
    duration: '6 mos',
    location: 'Boston, Massachusetts, United States · Remote',
    description: 'Designed a program to make the internal insurance system easier to navigate and collaborated with other Co-Op members to create an onboarding website.',
  },
  {
    title: 'Technology Intern',
    company: 'Edutek',
    date: 'Jun 2021 - Aug 2021',
    duration: '3 mos',
    location: 'Cortlandt, New York, United States',
    description: 'Managed data, handled equipment and inventory, and set up classroom technology infrastructure.',
  },

];

const TransferableJobs: React.FC = () => {
  return (
    <div className="jobs-section">
      <h1>Technical Experience</h1>
      <ul className="timeline-container">
        {transferableJobsData.map((job, index) => (
          <li key={index} className="timeline-item">
            <FontAwesomeIcon icon={faBriefcase} className="timeline-icon" />
            <div className="timeline-content">
              <b>{job.title}</b> at {job.company}
              <br />
              <i>{job.date}</i> 
             
              <p>{job.location}</p>
              <p>{job.description}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TransferableJobs;
