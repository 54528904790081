import React, { useRef } from 'react';
import './Header.css'; // Import the CSS file for the header

const Header: React.FC = () => {
  const headerRef = useRef<HTMLDivElement>(null);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element && headerRef.current) {
      const headerHeight = headerRef.current.offsetHeight;
      window.scrollTo({
        top: element.offsetTop - headerHeight,
        behavior: 'smooth',
      });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <header className="header" ref={headerRef}>
      <div className="navbar-container">
        <nav className="navbar">
          <button className="profile-button" onClick={scrollToTop}>Profile</button>
          <button onClick={() => scrollToSection('projects')}>Projects</button>
          <button onClick={() => scrollToSection('techstack')}>Tech Stack</button>
          <button onClick={() => scrollToSection('technicaljobs')}>Experience</button>
          {/* <button onClick={() => scrollToSection('transferablejobs')}>Transferable Jobs</button> */}
          <button onClick={() => scrollToSection('classes')}>Classes</button>
          <button onClick={() => scrollToSection('contactme')}>Contact Me</button> {/* New button */}
        </nav>
      </div>
    </header>
  );
};

export default Header;
